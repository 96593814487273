import React, { useEffect } from "react";
import "./mystaking.scss";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useWeb3React } from "@web3-react/core";
import Datatable from "./DataTable/Datatable";
import { Pagination } from "react-bootstrap";
import { TomiAllowance } from "../../hooks/allowence";
import { GetBalance } from "../../hooks/getBalance";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import environment from "../../utils/Enviroment";
import { getStackContract } from "../../utils/contractHelpers";
import { ApproveAllow } from "../../hooks/approvedAllowence";
import useWeb3 from "../../hooks/useWeb3";
import { getdopTokenContract } from "../../utils/contractHelpers";
import axios from "axios";
import Timer from "./Timer";
import StakingTable from "./DataTable/Datatable";
import Loader from "../../hooks/loader";
import Web3 from "web3";

const Mystaking = ({ indexwaits, indexwait, openmodal, setOpenmodal, setShow111 }) => {
  var currencyFormatter = require("currency-formatter");
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const { GetBal } = GetBalance();
  const [balanceOf, setBalanceOf] = useState("");
  // console.log("balance here ", balanceOf)
  const { AllowanceTomi } = TomiAllowance();
  const [allowance, setAllowance] = useState("");
  const [errormessage, seterrormessage] = useState("");
  // console.log("balance here ", allowance)
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [MyDopBalance, setMyDopBalance] = useState("");
  const [MyDopBalanceactive, setMyDopBalanceactive] = useState("");
  const [MyDopBalanceTotalhere, setMyDopBalanceTotalhere] = useState("");
  var rewardPerYear = 210000000;
  var apy = (rewardPerYear / MyDopBalanceTotalhere) * 100
  // console.log("asasas", MyDopBalance)
  const [clamiedrewardss, setclamiedrewardss] = useState("");
  const [clamiedreward, setclamiedreward] = useState("");
  // console.log("datahere is", clamiedreward, clamiedrewardss)
  const [Amountclamiednow, setAmountclamiednow] = useState();
  // console.log("amount claimed", Amountclamiednow)
  const [activeDeposit, setActiveDeposit] = useState([]);
  // console.log("data here", activeDeposit)
  const [valueamount, setvalueamount] = useState();
  const [valueamount1, setvalueamount1] = useState();
  const [unstakedvalue, setunstakedvalue] = useState();
  const [stakedvalue, setstakedvalue] = useState();
  const [Restakedvalue, setRestakedvalue] = useState("");
  const [loader, setLoader] = useState(false);
  const [claimabletime, setclaimabletime] = useState();
  const [timeIsUp, setTimeIsUp] = useState(false);
  const { ApproveTokenAllow } = ApproveAllow();
  const [activeDepositCount, setActiveDepositCount] = useState(1);
  const [mainLoader, setMainLoader] = useState(false);
  const [text, settext] = useState("Loading");

  const [stakegas, setstakegas] = useState("");
  // const [Restakedvalue, setRestakedvalue] = useState("");
  // const [Restakedvalue, setRestakedvalue] = useState("");
  // const [Restakedvalue, setRestakedvalue] = useState("");
  // const [Restakedvalue, setRestakedvalue] = useState("");

  // console.log("is time up", timeIsUp)

  const [show, setShow] = useState(false);
  const handleClose = (stake) => {
    setShow(false);
    setOpenmodal(false);
    if (!stake) {
      setvalueamount("");
    }
  };
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => {
    setShow1(false);
    setOpenmodal(false);
    setvalueamount("");
  }
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => {
    setShow2(false);
    setOpenmodal(false);
    setstakedvalue("");
  };
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = (unsatk) => {
    setShow3(false);
    if (!unsatk) {
      setvalueamount1("");
    }
  };
  const handleShow3 = () => {
    // console.log("valur herw")
    DopBalanceCheck();
    setShow3(true);
  }

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => {
    setShow4(false);
    setvalueamount1("");
  };
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => {
    setunstakedvalue("");
    setShow5(false);
  };
  const handleShow5 = () => setShow5(true);

  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => {
    ClaimableRewarCheck();
    ClaimableRewarChecks();
    setShow6(true);
  };
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => {
    ClaimableRewarCheck();
    ClaimableRewarChecks();
    setShow9(true);
  };

  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);

  const [show11, setShow11] = useState(false);
  const handleClose11 = () => {
    ClaimableRewarCheck();
    setShow11(false);
  }
  const handleShow11 = () => setShow11(true);

  const [show13, setShow13] = useState(false);
  const handleClose13 = () => {
    setShow13(false);
  }
  const handleShow13 = () => setShow13(true);

  const [show12, setShow12] = useState(false);
  const handleClose12 = () => {
    ClaimableRewarCheck();
    setShow12(false);
  }
  const handleShow12 = () => setShow12(true);

  const getBalance = async () => {
    let result = await GetBal(account);
    // let result2 = await GetHolder()
    // console.log("resulttvl", result2)
    setBalanceOf(result);
  };

  // const handleInputChange = (event) => {
  //   if (event.target.value != "") {
  //     setvalueamount(event.target.value);
  //     setstakedvalue(event.target.value);
  //   } else {
  //     setvalueamount();
  //   }
  // };
  const handleInputChange = (event) => {
    const value = event.target.value;
    // Remove any non-numeric characters from the input value
    const sanitizedValue = value.replace(/[^0-9.]/g, '');
    // Regex to match numbers only (without allowing +, -, e)
    const isValidNumber = /^\d*\.?\d*$/.test(sanitizedValue);

    if (isValidNumber) {
      if (sanitizedValue !== "") {
        setvalueamount(sanitizedValue);
        setstakedvalue(sanitizedValue);
      } else {
        setvalueamount("");
      }
    }
  };


  const handleMaxBal = () => {
    setvalueamount(truncateToFourDecimals(balanceOf));
    setstakedvalue(parseFloat(balanceOf).toFixed(3));
    if (balanceOf > 0) {
    }
  };


  const handleMaxBal1 = () => {
    setvalueamount1(truncateToFourDecimals(MyDopBalance));
    setunstakedvalue(parseFloat(MyDopBalance).toFixed(3));
  };

  const handleInputChange1 = (event) => {
    // console.log("sdsd")
    const value = event.target.value;
    // Remove any non-numeric characters from the input value
    const sanitizedValue = value.replace(/[^0-9.]/g, '');
    // Regex to match numbers only (without allowing +, -, e)
    const isValidNumber = /^\d*\.?\d*$/.test(sanitizedValue);

    if (isValidNumber) {
      if (sanitizedValue !== "") {
        setvalueamount1(sanitizedValue);
        setunstakedvalue(sanitizedValue);
      } else {
        setvalueamount1("");
      }
    }
  };

  const StackingAllowenceCheck = async () => {
    try {
      // setMainLoader(true);
      let result = await AllowanceTomi();
      setAllowance(result);
      // console.log("error allownce get", result);
      // setMainLoader(false);
    } catch {
      console.log("error allownce get");
    }
  };

  const DopBalanceCheck = async () => {
    const contractAddress = environment?.StackContact;
    const contract = getStackContract(contractAddress, web3);
    try {
      // console.log("total");
      const approved = await contract.methods.stakes(account).call();
      // console.log("approved value here", approved)
      const currentTime = Math.floor(Date.now() / 1000);
      if (approved?.claimTime > currentTime) {
        // console.log("value", approved?.amount / 10 ** 18)
        setclaimabletime(approved?.claimTime);
        const dopbal = approved?.amount / 10 ** 18
        const restakbal = approved?.restakedAmount / 10 ** 18
        setMyDopBalance(dopbal - restakbal);
        setRestakedvalue(approved?.restakedAmount / 10 ** 18)
        setMyDopBalanceactive(approved?.amount / 10 ** 18)
      }
      else {
        setMyDopBalance(approved?.amount / 10 ** 18);
        setRestakedvalue(approved?.restakedAmount / 10 ** 18)
        setclaimabletime(approved?.claimTime);
        setMyDopBalanceactive(approved?.amount / 10 ** 18)
      }
    } catch (error) {
      console.log("error allownce get");
    }
    // setMainLoader(false);
  };

  const ClaimableRewarCheck = async () => {
    console.log("every1 minute")
    const contractAddress = environment?.StackContact;
    const contract = getStackContract(contractAddress, web3);
    try {
      // console.log("total");
      const clamieb = await contract.methods.getReward(account).call();
      // console.log("here value",clamieb / 10 ** 18)
      setclamiedreward((clamieb / 10 ** 18).toFixed(3));
    } catch (error) {
      console.log("error allownce get");
    }
    // setMainLoader(false);
  };

  // Use useEffect to run the function every minute
  useEffect(() => {
    // console.log("aaaaaaaa_++++++++++++++++++++++++++")
    const intervalId = setInterval(ClaimableRewarCheck, 60000); // 60000 milliseconds = 1 minute

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [account, indexwaits]);

  const ClaimableRewarChecks = async () => {
    const contractAddress = environment?.StackContact;
    const contract = getStackContract(contractAddress, web3);
    try {
      // console.log("total");
      const clamieb = await contract.methods.getReward(account).call();
      setclamiedrewardss((clamieb / 10 ** 18).toFixed(3));
    } catch (error) {
      console.log("error allownce get");
    }
    // setMainLoader(false);
  };

  function truncateToFourDecimals(number) {
    const factor = Math.pow(10, 6); // 10^6 = 1000000
    return Math.floor(number * factor) / factor;
  }

  const StakeFun = async () => {
    var resultssss = await AllowanceTomi();
    // console.log("allowence", resultssss)

    if (account) {
      try {
        if (
          valueamount <= "" ||
          valueamount === undefined ||
          valueamount <= 0
        ) {
          seterrormessage('Please enter dop value to stake')
          handleShow13()

          // toast.error("Please enter dop value to stake", {
          //   position: "top-center",
          //   autoClose: 5000,
          // });
        } else if (valueamount > balanceOf) {
          seterrormessage('The entered amount exceeds your staked DOP balance')
          handleShow13()
          // toast.error("Selected value must be smaller then your dop balance", {
          //   position: "top-center",
          //   autoClose: 5000,
          // });
        } else if (resultssss >= parseFloat(valueamount) === true) {
          // setMainLoader(true);
          const contractAddress = environment?.StackContact;
          const contract = getStackContract(contractAddress, web3);
          try {

            settext("Confirm Stake Transaction And Wait...")
            setMainLoader(true);
            let value2 = truncateToFourDecimals(valueamount);
            // console.log("vavlue", value2)
            // let amount11 = parseFloat(valueamount).toFixed(5)
            // console.log("float value", amount11)
            // let amount1 = Math.ceil((amount11) * 1e12) / 1e12;
            // console.log("float value", amount1)
            let bal = web3.utils.toWei(value2?.toString(), "ether");
            // console.log("value here is", bal)

            var gasFunPrice;
            web3.eth.getGasPrice().then((result) => {
              var result2 = parseInt(result) + 3000000000;
              gasFunPrice = result2.toString();
            });
            const gas = await contract.methods
              .stake(bal)
              .estimateGas({ from: account });
            const gaslimit = gas + parseInt(15000)
            const balance = await contract.methods
              .stake(bal)
              .send({ from: account, gas: gaslimit, gasPrice: gasFunPrice });
            if (balance) {
              setTimeout(() => {
                setTimeIsUp(false);
                getBalance();
                StackingAllowenceCheck();
                handleClose1();
                DopBalanceCheck();
                getDepositDataAmount();
                getDepositData(currentPage, pageSize);
                handleShow2();
                setMainLoader(false);
                settext("Loading...")
                ClaimableRewarCheck();
                ClaimableRewarChecks();
              }, 1000);
              setvalueamount("");
              StackingAllowenceCheck();
            }
            setvalueamount("");
          } catch (error) {
            setMainLoader(false);
            settext("Loading...")
            if (error.code === -32000) {
              seterrormessage('Insufficient Eth For Fee')
              handleShow13()
              // toast.warning("Insufficient Eth For Fee", {
              //   position: "top-center",
              //   autoClose: 4000,
              // });
              console.log("9999999", error.code);
              throw error;
            } else {
              seterrormessage(error.message)
              handleShow13()
              // toast.error(error.message, {
              //   position: "top-center",
              //   autoClose: 4000,
              // });
              console.log("9999999", error);
              throw error;
            }
          }
        } else {
          if (account) {
            // toast.warning("Please Approve Your Coins First", {
            //   position: "top-center",
            //   autoClose: 4000,
            // });
            if (balanceOf > 0) {
              try {
                settext("Confirm Approve Transaction And Wait...")
                setMainLoader(true);
                var result = await ApproveTokenAllow();
              } catch (error) {
                setMainLoader(false);
                settext("Loading...")
              }
              if (result) {
                // toast.success("Coins Approved Successfully", {
                //   position: "top-center",
                //   autoClose: 4000,
                // });
                let resultss = await AllowanceTomi();
                console.log("allowence", resultss)
                if (valueamount <= resultss) {
                  const contractAddress = environment?.StackContact;
                  const contract = getStackContract(contractAddress, web3);
                  try {
                    settext("Confirm Stake Transaction And Wait...")
                    setMainLoader(true);
                    // let amount11 = parseFloat(valueamount).toFixed(4)
                    let value2 = truncateToFourDecimals(valueamount);
                    // let amount1 = Math.round((amount11) * 1e12) / 1e12;
                    let bal = web3.utils.toWei(value2?.toString(), "ether");
                    console.log("value here is", bal)

                    var gasFunPrice;
                    web3.eth.getGasPrice().then((result) => {
                      var result2 = parseInt(result) + 3000000000;
                      gasFunPrice = result2.toString();
                    });

                    const gas = await contract.methods
                      .stake(bal)
                      .estimateGas({ from: account });
                    const gaslimit = gas + parseInt(15000)


                    const balance = await contract.methods
                      .stake(bal)
                      .send({ from: account, gas: gaslimit, gasPrice: gasFunPrice });
                    if (balance) {
                      setTimeout(() => {
                        setTimeIsUp(false);
                        getBalance();
                        StackingAllowenceCheck();
                        handleClose1();
                        DopBalanceCheck();
                        getDepositDataAmount();
                        getDepositData(currentPage, pageSize);
                        handleShow2();
                        setMainLoader(false);
                        settext("Loading...")
                        ClaimableRewarCheck();
                        ClaimableRewarChecks();
                      }, 1000);
                      setvalueamount("");
                      StackingAllowenceCheck();
                    }
                    setvalueamount("");
                  } catch (error) {
                    setMainLoader(false);
                    settext("Loading...")
                    if (error.code === -32000) {
                      seterrormessage('Insufficient Eth For Fee')
                      handleShow13()
                      // toast.warning("Insufficient Eth For Fee", {
                      //   position: "top-center",
                      //   autoClose: 4000,
                      // });
                      console.log("9999999", error.code);
                      throw error;
                    } else {
                      // toast.error(error.message, {
                      //   position: "top-center",
                      //   autoClose: 4000,
                      // });
                      seterrormessage(error.message)
                      handleShow13()
                      // toast.error(
                      //   "execution reverted may be becuase of dop coins you are staking more then your allownce",
                      //   {
                      //     position: "top-center",
                      //     autoClose: 4000,
                      //   }
                      // );
                      console.log("9999999", error);
                      throw error;
                    }
                  }
                } else {
                  setMainLoader(false);
                  seterrormessage('Your entered amount must be equal or less then your allownce approved')
                  handleShow13()
                  // toast.error(
                  //   "Your entered value must be equal or less then your allownce approved",
                  //   {
                  //     position: "top-center",
                  //     autoClose: 5000,
                  //   }
                  // );
                }
              }
            } else {
              setMainLoader(false);
              // toast.error("Dop balance must be greater then 0", {
              //   position: "top-center",
              //   autoClose: 5000,
              // });
              seterrormessage('Dop balance must be greater then 0')
              handleShow13()
            }
          } else {
            seterrormessage('Please connect your wallet first to approve')
            handleShow13()
            // toast.error("Please connect your wallet first to approve", {
            //   position: "top-center",
            //   autoClose: 5000,
            // });
          }
        }
      } catch {
        // setMainLoader(false);
      }
    } else {
      seterrormessage('Please Connect Your Wallet First')
      handleShow13()
      // toast.error("Please Connect Your Wallet First", {
      //   position: "top-center",
      //   autoClose: 5000,
      // });
    }
  };

  const UnstakeFun = async () => {
    if (account) {
      try {
        // setMainLoader(true)
        if (valueamount1 <= MyDopBalance) {
          // setMainLoader(true);
          const contractAddress = environment?.StackContact;
          const contract = getStackContract(contractAddress, web3);
          try {
            settext("Confirm Unstake Transaction And Wait...")
            setMainLoader(true);
            // let amount11 = parseFloat(valueamount1).toFixed(4)
            // console.log("value here is",amount11)
            // let amount1 = Math.ceil((amount11) * 1e12) / 1e12;
            // console.log("value here is",amount1)
            let value2 = truncateToFourDecimals(valueamount1);
            // let value1 = parseFloat(valueamount1).toFixed(4)
            let bal = web3.utils.toWei(value2?.toString(), "ether");
            console.log("value here is", bal)

            var gasFunPrice;
            web3.eth.getGasPrice().then((result) => {
              var result2 = parseInt(result) + 3000000000;
              gasFunPrice = result2.toString();
            });

            const gas = await contract.methods
              .requestUnstake(bal)
              .estimateGas({ from: account });

            const gaslimit = gas + parseInt(15000)

            const balance = await contract.methods
              .requestUnstake(bal)
              .send({ from: account, gas: gaslimit, gasPrice: gasFunPrice });
            if (balance) {
              setTimeout(() => {
                getBalance();
                handleClose4();
                DopBalanceCheck();
                handleShow5();
                setvalueamount1("");
                setMainLoader(false);
                settext("Loading...")
                ClaimableRewarCheck();
                ClaimableRewarChecks();
              }, 1000);
            }
          } catch (error) {
            setMainLoader(false);
            settext("Loading...")
            if (error.code === -32000) {
              seterrormessage('Insufficient Eth For Fee')
              handleShow13()
              // toast.warning("Insufficient Eth For Fee", {
              //   position: "top-center",
              //   autoClose: 4000,
              // });
              console.log("9999999", typeof error.code);
              throw error;
            } else {
              seterrormessage(error.message)
              handleShow13()
              // toast.error(error.message, {
              //   position: "top-center",
              //   autoClose: 4000,
              // });
              console.log("9999999", error);
              throw error;
            }
          }
        } else {
          seterrormessage('The entered amount exceeds your staked DOP balance')
          handleShow13()
          // toast.error("Selected value must be smaller then your Staked Dop", {
          //   position: "top-center",
          //   autoClose: 5000,
          // });
        }
      } catch {
        // setMainLoader(false);
      }
    } else {
      seterrormessage('Please Connect Your Wallet First')
      handleShow13()
      // toast.error("Please Connect Your Wallet First", {
      //   position: "top-center",
      //   autoClose: 5000,
      // });
    }
  };

  const RestateFun = async () => {
    ClaimableRewarChecks();
    ClaimableRewarCheck();
    if (account) {
      try {
        // setMainLoader(true);
        const contractAddress = environment?.StackContact;
        const contract = getStackContract(contractAddress, web3);
        try {
          settext("Confirm Restake Transaction And Wait...")
          setMainLoader(true);
          var gasFunPrice;
          web3.eth.getGasPrice().then((result) => {
            var result2 = parseInt(result) + 3000000000;
            gasFunPrice = result2.toString();
          });
          const gas = await contract.methods
            .claimAndRestake()
            .estimateGas({ from: account });
          const gaslimit = gas + parseInt(15000)
          const balance = await contract.methods
            .claimAndRestake()
            .send({ from: account, gas: gaslimit, gasPrice: gasFunPrice });
          if (balance) {
            setTimeout(() => {
              setTimeIsUp(false);
              getBalance();
              DopBalanceCheck();
              getDepositDataAmount();
              getDepositData(currentPage, pageSize);
              handleClose9();
              handleShow10();
              setMainLoader(false);
              settext("Loading...")
              ClaimableRewarCheck();
            }, 1000);
          }
        } catch (error) {
          setMainLoader(false);
          settext("Loading...")
          if (error.code === -32000) {
            seterrormessage('Insufficient Eth For Fee')
            handleShow13()
            // toast.warning("Insufficient Eth For Fee", {
            //   position: "top-center",
            //   autoClose: 4000,
            // });
            console.log("9999999", typeof error.code);
            throw error;
          } else {
            seterrormessage(error.message)
            handleShow13()
            // toast.error(error.message, {
            //   position: "top-center",
            //   autoClose: 4000,
            // });
            console.log("9999999", error);
            throw error;
          }
        }
      } catch {
        // setMainLoader(false);
      }
    } else {
      seterrormessage('Please Connect Your Wallet First')
      handleShow13()
      // toast.error("Please Connect Your Wallet First", {
      //   position: "top-center",
      //   autoClose: 5000,
      // });
    }
  };

  const claimFun = async () => {
    ClaimableRewarCheck();
    ClaimableRewarChecks();
    if (account) {
      try {
        // setMainLoader(true);
        const contractAddress = environment?.StackContact;
        const contract = getStackContract(contractAddress, web3);
        try {
          settext("Confirm Claim Transaction And Wait...")
          setMainLoader(true);
          var gasFunPrice;
          web3.eth.getGasPrice().then((result) => {
            var result2 = parseInt(result) + 3000000000;
            gasFunPrice = result2.toString();
          });
          const gas = await contract.methods
            .claim()
            .estimateGas({ from: account });
          const gaslimit = gas + parseInt(15000)
          const balance = await contract.methods
            .claim()
            .send({ from: account, gas: gaslimit, gasPrice: gasFunPrice });
          if (balance) {
            setTimeout(() => {
              getBalance();
              DopBalanceCheck();
              getClaimeduserdata();
              handleClose6();
              handleShow7();
              setMainLoader(false);
              settext("Loading...")
              ClaimableRewarCheck();
            }, 1000);
          }
        } catch (error) {
          setMainLoader(false);
          settext("Loading...")
          if (error.code === -32000) {
            seterrormessage('Insufficient Eth For Fee')
            handleShow13()
            // toast.warning("Insufficient Eth For Fee", {
            //   position: "top-center",
            //   autoClose: 4000,
            // });
            console.log("9999999", typeof error.code);
            throw error;
          } else {
            seterrormessage(error.message)
            handleShow13()
            // toast.error(error.message, {
            //   position: "top-center",
            //   autoClose: 4000,
            // });
            console.log("9999999", error);
            throw error;
          }
        }
      } catch {
        // setMainLoader(false);
      }
    } else {
      seterrormessage('Please Connect Your Wallet First')
      handleShow13()
      // toast.error("Please Connect Your Wallet First", {
      //   position: "top-center",
      //   autoClose: 5000,
      // });
    }
  };

  const getDepositDataAmount = () => {
    // setMainLoader(true);
    let acc = JSON.stringify(account);
    const first = 1000;
    var data = JSON.stringify({
      query: `query MyQuery {
          stakeds(where: {staker: ${acc}}, first: ${first}) {
            id
          }
        }
      `,
    });
    var config = {
      method: "post",
      url: environment.StackGraph,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        // console.log("sdsdssd", response);
        setActiveDepositCount(response?.data?.data?.stakeds?.length);
        // setMainLoader(false);
      })
      .catch(function (error) {
        // setMainLoader(false);
      });
  };

  const getDepositData = (page = 1, pageSize = 10) => {
    settext("Fetching your staking data....")
    setMainLoader(true);
    let acc = JSON.stringify(account);
    const skip = (page - 1) * pageSize;
    const first = pageSize;

    var data = JSON.stringify({
      query: `query MyQuery {
        stakeds(where: {staker: ${acc}}, first: ${first}, skip: ${skip}, orderBy: blockTimestamp, orderDirection: desc) {
          amount
          claimTime
          blockTimestamp
          staker
          transactionHash
        }
      }`,
    });

    var config = {
      method: "post",
      url: environment.StackGraph,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setActiveDeposit(response?.data?.data?.stakeds);
        setMainLoader(false);
        settext("Loading...")
      })
      .catch(function (error) {
        setMainLoader(false);
        settext("Loading...")
        console.error(error);
      });
  };

  const getClaimeduserdata = () => {
    let acc = JSON.stringify(account);
    let accounthere = acc.toLocaleLowerCase();

    var data = JSON.stringify({
      query: `query MyQuery {
          stakers(where: {id: ${accounthere}}) {
            totalAmountClaimed
          }
        }`,
    });

    var config = {
      method: "post",
      url: environment.StackGraph,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log("ammount claimed", response)
        setAmountclamiednow(
          response?.data?.data?.stakers[0]?.totalAmountClaimed
        );
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const DopBalanceCheckTOTAL = async () => {
    // console.log("total");
    const contractAddress = environment?.StackContact;
    const contract = getStackContract(contractAddress, web3);
    try {
      // console.log("total");
      const approved = await contract.methods.totalStaked().call();
      // let balance = parseInt(approved) / 300000000000000000
      // console.log("balance", approved);
      setMyDopBalanceTotalhere(approved / 10 ** 18);
    } catch (error) {
      console.log("error get");
    }
    // setMainLoader(false);
  };

  useEffect(() => {

    if (account) {
      // console.log("value here is++++++++++++++++++++++++++++")

      getBalance();
      StackingAllowenceCheck();
      DopBalanceCheck();
      ClaimableRewarCheck();
      ClaimableRewarChecks();
      getDepositDataAmount();
      getClaimeduserdata();
      DopBalanceCheckTOTAL();


    } else {
      // setBalanceOf(0);
      setMyDopBalance(0);
      setclamiedreward(0);
      setActiveDeposit([]);
      setAmountclamiednow(0);
      setActiveDepositCount(0);
      setMyDopBalanceactive(0)
      setRestakedvalue(0)
    }
  }, [account]);

  // console.log("account", account)

  const toasterrormodal = () => {
    handleShow11();
  };
  const toasterrormodals = () => {
    handleShow12();
  };

  const toasterrormodalss = () => {
    handleShow8();
  };

  const calculateTimeLeft = () => {
    const currentTime = Math.floor(Date.now() / 1000);
    const timeLeft = claimabletime - currentTime + 10; // Added 10 seconds here
    return Math.max(timeLeft, 0);
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    // Update the timer every second
    const interval = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();

      if (newTimeLeft <= 0) {
        clearInterval(interval);
        setTimeIsUp(true);
      }

      setTimeLeft(newTimeLeft);
    }, 1000);

    return () => clearInterval(interval);
  }, [claimabletime, calculateTimeLeft()]);


  useEffect(() => {
    if (openmodal === true) {
      handleShow();
    }
  }, [openmodal]);

  // Function to format time
  const formatTime = (seconds) => {
    const days = Math.floor(seconds / (24 * 3600));
    seconds %= 24 * 3600;
    const hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    const minutes = Math.floor(seconds / 60);
    seconds %= 60;

    return (
      <>
        <h5>Claim Available in</h5>
        <h6 className="mt-1">{days || 0}D:{hours || 0}H:{minutes || 0}M:{seconds || 0}S</h6>
      </>
    );
  };


  // const StakeFunGas = async () => {
  //   const contractAddress = environment?.StackContact;
  //   const contract = getStackContract(contractAddress, web3);
  //   const chainId = process.env.REACT_APP_NODE_1;
  //   const web3Gas = new Web3(new Web3.providers.HttpProvider(chainId));
  //   const gasP = await web3Gas.eth.getGasPrice();
  //   const gasPWei = parseInt(gasP) / 10 ** 9;

  //   try {
  //     let nd = web3.utils.toWei(valueamount.toString(), "ether");

  //     const gas = await contract.methods
  //       .stake(nd?.toString())
  //       .estimateGas({ from: account });

  //     const gf = parseInt(gas.toString()) / 10 ** 9;

  //     // Calculate additional 10 gwei in wei
  //     const additionalGasFeeGwei = 3000000000;
  //     // const additionalGasFeeWei = additionalGasFeeGwei * 10 ** 9;

  //     // Add the additional gas fee
  //     const totalGasFeeWei = (parseInt(gas.toString()) + additionalGasFeeGwei);
  //     const finalGasFee = (totalGasFeeWei / 10 ** 9) * gasPWei;

  //     console.log("value here ", finalGasFee);
  //   } catch (error) {
  //     throw error;
  //   }
  // };

  const stakeFunc = () => {
    // console.log("here is")
    if (valueamount <= "" ||
      valueamount === undefined ||
      valueamount <= 0) {
      seterrormessage('Enter amount must be greater than 0')
      handleShow13()
      // toast.error("Enter amount must be greater then 0", {
      //   position: "top-center",
      //   autoClose: 5000,
      // });
    }
    else {
      handleShow1();
      handleClose("stake");
    }

  }

  const unstakeFunc = () => {
    // console.log("here is")
    if (valueamount1 <= "" ||
      valueamount1 === undefined ||
      valueamount1 <= 0) {
      seterrormessage('Enter amount must be greater than 0')
      handleShow13()
      // toast.error("Enter amount must be greater then 0", {
      //   position: "top-center",
      //   autoClose: 5000,
      // });
    }
    else {
      handleShow4();
      handleClose3("unstake");
    }

  }

  // console.log("value", valueamount1)


  return (
    <>
      {mainLoader && <Loader text={text} />}

      <section className="mystaking">
        <div className="custom-container paddmobilezero p-0">
          {/* <h5 className="mystakinghead">My Staking Stats</h5> */}
          {account ?
            (
              <div className="mystakingmain">
                <div className="leftstaking">
                  <h5 className="leftstakinghead">My DOP Staking</h5>
                  <div className="stakingdetailmain">
                    <div className="stakingdetailinner">
                      <p className="stakingdetailpara">DOP Staked</p>
                      <h6 className="stakingdetailhead">
                        {MyDopBalanceactive ? (parseFloat(MyDopBalanceactive).toLocaleString()) : 0} DOP
                      </h6>
                    </div>
                    <div className="stakingdetailinner">
                      <p className="stakingdetailpara">CLAIMED REWARDS</p>
                      <h6 className="stakingdetailhead">
                        {Amountclamiednow
                          ? parseFloat(Amountclamiednow / 10 ** 18).toLocaleString()
                          : 0}{" "}
                        DOP
                      </h6>
                    </div>
                  </div>
                  {/* <div className="rewardsmain"> */}
                  {/* <div className="rewardsleft">
                  <p className="rewardleftpara">Claimable RewardS</p>
                  <h6 className="rewardlefthead">
                    {clamiedreward ? clamiedreward : 0} DOP
                  </h6>
                </div>
                <div className="rewardsright">
                  {clamiedreward > 0 ? (
                    <button onClick={handleShow9} className="restakebtn">
                      Restake
                    </button>
                  ) : (
                    <button
                      onClick={toasterrormodal}
                      className="restakebtn sdjsgdfhsdgfh"
                    >
                      Restake
                    </button>
                  )}

                  {clamiedreward > 0 ? (
                    <button onClick={handleShow6} className="claimbtn">
                      Claim
                    </button>
                  ) : (
                    <button
                      onClick={toasterrormodals}
                      className="claimbtn sdjsgdfhsdgfh"
                    >
                      Claim
                    </button>
                  )}
                </div> */}

                  {/* <div className="rewardsrightparas">
                  <p className="yellowpara">Next Claim In</p>
                  <p className="yellowhead">70D:32H:47M:12S</p>
                </div> */}
                  {/* </div> */}
                  <div className="newbox">
                    <div className="parentbox">
                      <div className="left">
                        <h6>Claimable RewardS</h6>
                        <p className="sjdasjashdjasdjgsa">{clamiedreward ? parseFloat(clamiedreward).toLocaleString() : 0} DOP </p>
                      </div>
                      <div className="left">
                        <h6>DOP restaked</h6>
                        <p className="sjdasjashdjasdjgsa">{Restakedvalue ? (parseFloat(Restakedvalue).toLocaleString()) : 0} DOP</p>
                      </div>
                    </div>
                    <div className="endbtnsssss">
                      {/* <div className="timersss">
                    <h6>Claimable in</h6>
                    <p>70D:32H:47M:12S</p>
                  </div> */}

                      {clamiedreward > 0 ? (
                        <>
                          {timeIsUp ? (
                            <button onClick={handleShow6} className="claimssssss">Claim</button>
                          ) : (
                            <button className="claimssssss jhcbdshjbchbdshc">
                              {formatTime(timeLeft)}
                            </button>
                          )}
                        </>
                      ) : (
                        <button onClick={toasterrormodals} className="claimssssss">Claim</button>
                      )}

                      {clamiedreward > 0 ? (
                        <button onClick={handleShow9} className="staksssss">Restake</button>
                      ) : (
                        <button onClick={toasterrormodal} className="staksssss">Restake</button>
                      )}
                    </div>
                  </div>
                  {account ?
                    (
                      <button onClick={handleShow} className="stakebtn">
                        Stake
                      </button>
                    )
                    :
                    (
                      <button onClick={toasterrormodalss} className="stakebtn">
                        Stake
                      </button>
                    )
                  }

                  {account ?
                    (
                      <button onClick={handleShow3} className="unstakebtn">
                        UnStake
                      </button>
                    )
                    :
                    (
                      <button onClick={toasterrormodalss} className="unstakebtn">
                        UnStake
                      </button>
                    )
                  }

                </div>
                <div className="rightstaking">
                  <h5 className="rightstakinghead">My ACTIVITY</h5>

                  <StakingTable
                  indexwait={indexwait}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    indexwaits={indexwaits}
                    activeDepositCount={activeDepositCount}
                    activeDeposit={activeDeposit}
                    account={account}
                    getDepositData={getDepositData}
                  />
                </div>
              </div>
            )
            :
            (
              <div className="stakingstatbox">
                <div className="wrapper">
                  <img src="\assets\upload.png" alt="img" className="img-fluid" />
                  <p className="whitetext">Your DOP Staking stats will appear here </p>
                  <button className="waletconnectss" onClick={() => { setShow111(true) }}>Connect Wallet</button>
                </div>
              </div>
            )
          }


          {/* connect wallet screen  */}


        </div>
      </section>

      <Modal
        className="generic-modal stakingmodals"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Stake</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="stakecard">
            <div className="textcard">
              <div className="parent">
                <h4 className="uppertext">Enter $DOP amount to stake</h4>
                {/* <p className="para">1,254.56</p> */}
                <input
                  onChange={handleInputChange}
                  value={valueamount}
                  type="text" // Change to text to better control input validation
                  placeholder="0.00"
                  pattern="\d*\.?\d*" // HTML5 pattern attribute to allow numbers and decimal only
                />
                <h6 onClick={handleMaxBal} className="max">
                  MAX
                </h6>
              </div>
            </div>
          </div>
          <div className="textparent">
            <div className="innercontent">
              <h6 className="wallet">Wallet Balance</h6>
              <p className="textpara">
                {balanceOf ? parseFloat(balanceOf).toLocaleString() : 0} DOP
              </p>
            </div>
            <div className="innercontent">
              <h6 className="wallet">Reward APY %</h6>
              <p className="textpara">{apy && MyDopBalanceTotalhere ? parseFloat(apy).toFixed(2) : 23000.00}%</p>
            </div>
            {/* <div className="innercontent">
              <h6 className="wallet">Transaction Fee</h6>
              <p className="textpara">--</p>
            </div> */}
          </div>
          <div className="mainbtns">
            <button
              onClick={() => {
                handleClose();
              }}
              className="dullbtn"
            >
              Close
            </button>
            {/* diabledbtn */}
            <button
              className="brdrbtn"
              onClick={() => {
                stakeFunc();
                // handleShow1();
                // handleClose("stake");
                // StakeFunGas();
                // StakeFun();
              }}
            >
              Stake
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="generic-modal stakingmodals"
        show={show1}
        onHide={handleClose1}
        centered
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>Stake</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className="stakecard ">
            <div className="textcard ">
              <div className="parent">
                <h4 className="uppertext">Staking Amount</h4>
                <p className="para">{valueamount ? parseFloat(valueamount).toFixed(5) : 0}</p>
              </div>
            </div>
          </div>
          <div className="textparent">
            <div className="innercontent">
              <h6 className="wallet">Wallet Balance</h6>
              <p className="textpara">{balanceOf ? balanceOf : 0} DOP</p>
            </div>
            <div className="innercontent">
              <h6 className="wallet">Reward APY %</h6>
              <p className="textpara">{apy && MyDopBalanceTotalhere ? parseFloat(apy).toFixed(2) : 23000.00}%</p>
            </div>
            {/* <div className="innercontent">
              <h6 className="wallet">Transaction Fee</h6>
              <p className="textpara">$8.522 (0.01 ETH)</p>
            </div> */}
          </div>
          <div className="mainbtns">
            <button
              onClick={() => {
                handleClose1();
              }}
              className="dullbtn"
            >
              Cancel
            </button>
            <button
              className="brdrbtn"
              onClick={() => {
                // handleShow1();
                // handleClose();
                StakeFun();
              }}
            >
              Confirm
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="generic-modal stakingmodals"
        show={show2}
        onHide={handleClose2}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Stake</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="happy">
            <img
              src="\assets\happy.png"
              alt="img"
              className="img-fluid formsrgin"
            />
            <h6>{stakedvalue ? parseFloat(stakedvalue).toFixed(5) : 0} DOP</h6>
            <p>Staked successfully</p>
            <button className="commonbtn" onClick={handleClose2}>
              OK
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="generic-modal stakingmodals"
        show={show3}
        onHide={handleClose3}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Unstake</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="stakecard">
            <div className="textcard">
              <div className="parent">
                <h4 className="uppertext">Enter $DOP amount to Unstake</h4>
                {/* <p className="para">1,254.56</p> */}

                <input
                  onChange={handleInputChange1}
                  value={valueamount1}
                  type="text" // Change to text to better control input validation
                  placeholder="0.00"
                  pattern="\d*\.?\d*" // HTML5 pattern attribute to allow numbers and decimal only
                />
                <h6 onClick={handleMaxBal1} className="max">
                  MAX
                </h6>
              </div>
            </div>
          </div>
          <div className="textparent">
            <div className="innercontent">
              <h6 className="wallet">Available to Unstake</h6>
              <p className="textpara">{MyDopBalance ? MyDopBalance : 0} DOP</p>
            </div>
            {/* <div className="innercontent">
              <h6 className="wallet">Transaction Fee</h6>
              <p className="textpara">--</p>
            </div> */}
          </div>
          <div className="mainbtns">
            <button
              onClick={() => {
                handleClose3();
              }}
              className="dullbtn"
            >
              Close
            </button>
            <button
              className="brdrbtn"
              onClick={() => {
                unstakeFunc();
              }}
            >
              UnStake
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="generic-modal stakingmodals"
        show={show4}
        onHide={handleClose4}
        centered
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>Stake</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className="stakecard ">
            <div className="textcard">
              <div className="parent">
                <h4 className="uppertext">Unstaking Amount</h4>
                <p className="para">{valueamount1 ? parseFloat(valueamount1).toFixed(5) : 0}</p>
              </div>
            </div>
          </div>
          <div className="textparent">
            <div className="innercontent">
              <h6 className="wallet">Available to Unstake</h6>
              <p className="textpara">{MyDopBalance ? MyDopBalance : 0} DOP</p>
            </div>
            {/* <div className="innercontent">
              <h6 className="wallet">Transaction Fee</h6>
              <p className="textpara">$8.522 (0.01 ETH)</p>
            </div> */}
          </div>
          <div className="yellowbox">
            <h4 className="cool">cooldown Period</h4>
            <p className="yellowpara">
              After unstaking your DOP, you will be able to withdraw your tokens
              in 7 days
            </p>
          </div>
          <div className="mainbtns">
            <button
              onClick={() => {
                handleClose4();
              }}
              className="dullbtn"
            >
              Cancel
            </button>
            <button
              className="brdrbtn"
              onClick={() => {
                // handleShow5();
                // handleClose4();
                UnstakeFun();
              }}
            >
              Confirm
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="generic-modal stakingmodals"
        show={show5}
        onHide={handleClose5}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Unstaked</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="happy">
            <img
              src="\assets\happy.png"
              alt="img"
              className="img-fluid formsrgin"
            />
            <h6>{unstakedvalue ? parseFloat(unstakedvalue).toFixed(5) : 0} DOP</h6>
            <p>Unstaked successfully</p>
            <button className="commonbtn" onClick={handleClose5}>
              OK
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="generic-modal stakingmodals"
        show={show6}
        onHide={handleClose6}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Claim Rewards</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="stakecard ">
            <div className="textcard">
              <div className="parent">
                <h4 className="uppertext">Claimable Reward</h4>
                {/* {timeIsUp ? ( */}
                <p className="para">{clamiedreward ? clamiedreward : 0} DOP</p>
                {/* // <button  className="btn-claim">
                  //   Claim HYGT
                  // </button>
                ) : (
                  <p className="sdsdfadsfdsfsdf para">{formatTime(timeLeft)}</p>
                )} */}
              </div>
            </div>
          </div>

          <div className="mainbtns">
            <button
              onClick={() => {
                handleClose6();
              }}
              className="dullbtn"
            >
              Cancel
            </button>
            {timeIsUp === true ? (
              <button
                className="brdrbtn"
                onClick={() => {
                  // handleShow7();
                  // handleClose6();
                  claimFun();
                }}
              >
                Claim
              </button>
            ) : (
              <button className="brdrbtn buttonblur">Claim</button>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="generic-modal stakingmodals"
        show={show7}
        onHide={handleClose7}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Claim Rewards</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="happy">
            <img
              src="\assets\happy.png"
              alt="img"
              className="img-fluid 
formsrgin"
            />
            <h6>{clamiedrewardss ? clamiedrewardss : 0} DOP</h6>
            <p>Claimed successfully</p>
            <button
              className="commonbtn"
              onClick={() => {
                handleClose7();
              }}
            >
              OK
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="generic-modal stakingmodals"
        show={show8}
        onHide={handleClose8}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Wallet Connect</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="happy">
            <img
              src="\assets\vector.png"
              alt="img"
              className="img-fluid 
formsrgin"
            />

            <p className="wrong">Please Connect Your Wallet First</p>
            <button className="commonbtn" onClick={handleClose8}>
              OK
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="generic-modal stakingmodals"
        show={show13}
        onHide={handleClose13}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="happy">
            <img
              src="\assets\vector.png"
              alt="img"
              className="img-fluid 
formsrgin"
            />

            <p className="wrong">{errormessage ? errormessage : 'no error'}</p>
            <button className="commonbtn" onClick={handleClose13}>
              OK
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="generic-modal stakingmodals"
        show={show9}
        onHide={handleClose9}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Restake</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="stakecard ">
            <div className="textcard">
              <div className="parent">
                <h4 className="uppertext">Available DOP</h4>
                <p className="para">{clamiedreward ? clamiedreward : 0} DOP</p>
              </div>
            </div>
          </div>

          <div className="yellowbox">
            <h4 className="cool">Lockup Period</h4>
            <p className="yellowpara">
              Restaking your rewards will reset your lockup period to 90 days.
            </p>
          </div>
          <div className="mainbtns">
            <button
              onClick={() => {
                handleClose9();
              }}
              className="dullbtn"
            >
              Cancel
            </button>
            <button
              className="brdrbtn"
              onClick={() => {
                // handleShow10();
                // handleClose9();
                RestateFun();
              }}
            >
              Restake
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="generic-modal stakingmodals"
        show={show10}
        onHide={handleClose10}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Restake</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="happy">
            <img
              src="\assets\happy.png"
              alt="img"
              className="img-fluid formsrgin"
            />
            <h6>{clamiedrewardss ? clamiedrewardss : 0} DOP</h6>
            <p>Restaked successfully</p>
            <button
              className="commonbtn"
              onClick={() => {
                // handleShow11();
                handleClose10();
              }}
            >
              OK
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="generic-modal stakingmodals"
        show={show11}
        onHide={handleClose11}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Restake</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="happy">
            <img
              src="\assets\vector.png"
              alt="img"
              className="img-fluid formsrgin"
            />
            <h5 className="sjdgsdhjgdsjf">
              There are Two possible cases for having a Claimable Rewards balance
              of 0:
            </h5>
            <ol>
              {/* <li className="wrong sjdgsdhjgdsjf">If you have not staked any DOP coins, you need to stake DOP first.</li>
              <li className="wrong sjdgsdhjgdsjf">If you have already claimed all of your claimable rewards, you need to stake again to earn more rewards.</li> */}
              <li className="wrong sjdgsdhjgdsjf">
                If you have just restaked, wait 1 minutes for your rewards to
                start generating automatically.
              </li>
              <li className="wrong sjdgsdhjgdsjf">
                If your wallet is not connected then please connect your wallet first.
              </li>
            </ol>
            {/* <p className="wrong"></p> */}
            <button className="commonbtn" onClick={handleClose11}>
              OK
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="generic-modal stakingmodals"
        show={show12}
        onHide={handleClose12}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Claim</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="happy">
            <img
              src="\assets\vector.png"
              alt="img"
              className="img-fluid formsrgin"
            />
            <h5 className="sjdgsdhjgdsjf">
              There are three possible cases for having a Claimable Rewards balance
              of 0:
            </h5>
            <ol>
              <li className="wrong sjdgsdhjgdsjf">
                If you have not staked any DOP coins, you need to stake DOP
                first your reward will update every 1 minute.
              </li>
              <li className="wrong sjdgsdhjgdsjf">
                If you have already claimed all of your claimable rewards, you
                need to stake again to earn more rewards.
              </li>
              <li className="wrong sjdgsdhjgdsjf">
                If your wallet is not connected then please connect your wallet first.
              </li>
              {/* <li className="wrong sjdgsdhjgdsjf">If you have just restaked, wait 5 minutes for your rewards to start generating automatically.</li> */}
            </ol>
            {/* <p className="wrong"></p> */}
            <button className="commonbtn" onClick={handleClose12}>
              OK
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Mystaking;
