import React, { useEffect, useState } from "react";
import "./myunstaking.scss";
import { Pagination, Modal } from "react-bootstrap";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import environment from "../../utils/Enviroment";
import CountdownTimer from "./CountdownTimer";
import CooldownTimer from "./CountdownTimer";
import { getClaimingContract } from "../../utils/contractHelpers";
import useWeb3 from "../../hooks/useWeb3";
import { toast } from "react-toastify";
import Loader from "../../hooks/loader";
// import { Pagination, Modal } from "react-bootstrap";

const Myunstaking = ({ indexwaits, setShow111 }) => {
  const currencyFormatter = require("currency-formatter");
  const web3 = useWeb3();

  const { account } = useWeb3React();
  const [show, setShow] = useState(false);
  const [activeDeposit, setActiveDeposit] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [text, settext] = useState("Loading");
  const pageSize = 10;

  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const [timersUp, setTimersUp] = useState(
    Array(activeDeposit.length).fill(false)
  );
  const [mainLoader, setMainLoader] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const [timeIsUp, setTimeIsUp] = useState(false);
  const [activeDepositCount, setActiveDepositCount] = useState(1);

  // const [timeIsUpState, setTimeIsUpState] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(null); // State to store selected index
  const [recall, setRecall] = useState(false);
  const [timeIsUpState, setTimeIsUpState] = useState([]);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => {
    setShow2(false);
  };
  const handleShow2 = () => setShow2(true);

  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setTimeIsUpState([]);
    // Initialize timeIsUpState based on the length of deposits
    setTimeIsUpState(Array(activeDeposit.length).fill(false));
  }, [activeDeposit.length, currentPage]);

  useEffect(() => {
    if (account && currentPage === 1 && indexwaits === "MYUNSTAKING") {
      getDepositData(currentPage, pageSize);
      getDepositDataAmount();

      setRecall(true);
    } else if (account && indexwaits === "MYUNSTAKING") {
      getDepositData(currentPage, pageSize);
    } else {
      setActiveDeposit([]);
    }
  }, [account, indexwaits, currentPage, recall]);

  const getDepositDataAmount = () => {
    // setMainLoader(true);
    let acc = JSON.stringify(account);
    const first = 1000;
    var data = JSON.stringify({
      query: `query MyQuery {
        requestSets(where: {claimer: ${acc}}, first: ${first}) {
            id
          }
        }
      `,
    });
    var config = {
      method: "post",
      url: environment.StackGraph,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        setActiveDepositCount(response?.data?.data?.requestSets?.length);
        // setMainLoader(false);
      })
      .catch(function (error) {
        // setMainLoader(false);
      });
  };
  const getDepositData = (page = 1, pageSize = 5) => {
    const skip = (page - 1) * pageSize;
    const first = pageSize;
    setMainLoader(true);
    settext("Fetching your UnStaking data....");
    const acc = JSON.stringify(account);
    const data = JSON.stringify({
      query: `
      query MyQuery {
        requestSets(where: {claimer: ${acc}},first: ${first}, skip: ${skip}, orderBy: blockTimestamp, orderDirection: desc ) {
          index
          status
          amount
          claimRequestTime
          blockTimestamp
        }
      }`,
    });

    const config = {
      method: "post",
      url: environment.StackGraph,
      headers: { "Content-Type": "application/json" },
      data: data,
    };

    axios(config)
      .then((response) => {
        const deposits = response?.data?.data?.requestSets || [];

        setActiveDeposit(response?.data?.data?.requestSets || 0);
        setTotalItems(deposits.length);
        setMainLoader(false);
        settext("Loading...");
      })
      .catch((error) => {
        setMainLoader(false);
        settext("Loading...");
        console.error(error);
      });
  };

  const handleTimerComplete = (index) => {
    setTimeIsUpState((prevState) => {
      const newState = [...prevState];
      newState[index] = true;

      return newState;
    });
  };

  const withdraw = async (index) => {
    if (!account) {
      toast.error("Please Connect Your Wallet First");
      console.error("Please Connect Your Wallet First");
      return;
    }

    try {
      settext("Confirm Widhraw Transaction And Wait...");
      setMainLoader(true);

      const contractAddress = environment?.ClamingContract;
      const contract = getClaimingContract(contractAddress, web3);

      var gasFunPrice;
      web3.eth.getGasPrice().then((result) => {
        var result2 = parseInt(result) + 3000000000;
        gasFunPrice = result2.toString();
      });

      const gas = await contract?.methods
        ?.claimRequest(index)
        ?.estimateGas({ from: account });
      const gaslimit = gas + parseInt(15000)

    

      // const gasPrice = await web3.eth.getGasPrice();
      // const gasPriceWithBuffer = parseInt(gasPrice) + 3000000000;

      const responce = await contract.methods
        ?.claimRequest(index)
        ?.send({ from: account, gas: gaslimit, gasPrice: gasFunPrice });
      if (responce) {
        // toast.success("DOP's Withdrawl Successfully");

        getDepositData(currentPage, pageSize);
        getDepositDataAmount();
        handleShow2();
        setMainLoader(false);
        settext("Loading...");
      }
      // console.log("Withdraw successful");
    } catch (error) {
      handleShow8();
      // toast.error("Error while withdrawing");
      setMainLoader(false);
      settext("Loading...");
      console.error("Error while withdrawing:", error);
    }
  };

  // const paginatedData = activeDeposit.slice(
  //   (page - 1) * pageSize,
  //   page * pageSize
  // );
  const totalPages = Math.ceil(totalItems / pageSize);
  
  return (
    <>
      {mainLoader && <Loader text={text} />}
      <section className="parentmain">
        {account ?
          (
            <div className="custom-container paddmobilezero p-0">
              <div className="parentbar tabelmain">
                <h1 className="headingtable">My Unstakings</h1>
                <div className="maintablehaed">
                  <div className="innertable_user table-responsive">
                    <table>
                      <thead className="headtablee">
                        <tr>
                          <th>Date</th>
                          <th>Amount Staked</th>
                          <th>Cooldown Period</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="tablebody">
                        {activeDeposit?.map((deposit, index) => (
                          <tr key={index}>
                            <td className="datatableone">
                              <div className="mainimgdiv">
                                <p className="tabletext">
                                  {new Date(
                                    deposit?.blockTimestamp * 1000
                                  ).toLocaleDateString()}
                                </p>
                                <p className="secondtabletext">
                                  {new Date(
                                    deposit?.blockTimestamp * 1000
                                  ).toLocaleTimeString()}
                                </p>
                              </div>
                            </td>
                            <td className="datatableone addclass">
                              {(deposit?.amount / 1e18).toLocaleString()} DOP
                            </td>
                            {/* {timeIsUpState ? ( */}
                            {timeIsUpState[index] ? (
                              <td
                                className="datatableone secondclassagain"
                                key={index}
                              >
                                <CountdownTimer
                                  setTimeIsUp={() => handleTimerComplete(index)}
                                  targetEpochTime={deposit?.claimRequestTime}
                                />
                              </td>
                            ) : (
                              <td className="datatableone secondclass">
                                <CountdownTimer
                                  setTimeIsUp={() => handleTimerComplete(index)}
                                  targetEpochTime={deposit?.claimRequestTime}
                                />
                              </td>
                            )}
                            <td className="datatableone">
                              {timeIsUpState[index] && deposit.status ? (
                                <button
                                  onClick={() => {
                                    setWithdrawAmount(
                                      (deposit?.amount / 1e18).toLocaleString()
                                    );
                                    setSelectedIndex(deposit.index); // Set the selected index
                                    handleShow();
                                  }}
                                  className="thirdclass2"
                                >
                                  WITHDRAW
                                </button>
                              ) : (
                                <button className="thirdclass" disabled>
                                  {deposit.status ? " WITHDRAW Disable" : "Claimed"}
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  {
                    activeDepositCount > 0 &&   <div className="Paginationlattable">
                    <div className="left-f">
                      {/* <h6>SHOWING 1-10 OF 10</h6> */}
                    </div>
                    <div className="pagination">
                      <Pagination>
                        <Pagination.Prev
                          onClick={() =>
                            setCurrentPage((prev) => Math.max(prev - 1, 1))
                          }
                        />
                        {[
                          ...Array(Math.ceil(activeDepositCount / pageSize)).keys(),
                        ].map((number) => (
                          <Pagination.Item
                            key={number + 1}
                            active={number + 1 === currentPage}
                            onClick={() => setCurrentPage(number + 1)}
                          >
                            {number + 1}
                          </Pagination.Item>
                        ))}
                        <Pagination.Next
                          onClick={() => setCurrentPage((prev) => prev + 1)}
                        />
                      </Pagination>
                    </div>
                  </div>
                  }

                

                  {/* <div className="right-f">
                <Pagination>
                  <Pagination.Prev
                    onClick={() => {
                      setCurrentPage((prev) => Math.max(prev - 1, 1));
                      // setTimeIsUpState([]);
                    }}
                  />
                  {[
                    ...Array(Math.ceil(activeDepositCount / pageSize)).keys(),
                  ].map((number) => (
                    <Pagination.Item
                      key={number + 1}
                      active={number + 1 === currentPage}
                      onClick={() => {
                        setCurrentPage(number + 1);
                        // setTimeIsUpState([]);
                      }}
                    >
                      {number + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={() => {
                      setCurrentPage((prev) => prev + 1);
                      // setTimeIsUpState([]);
                    }}
                  />
                </Pagination>
              </div> */}
                </div>
              </div>
              <div className="mainheadphone d-none">
                <h1 className="headingstalking">My Unstakings</h1>
                <div>
                  {activeDeposit?.map((deposit, index) => (
                    <div className="cardone" key={index}>
                      <div className="flexclass">
                        <div className="headdate">
                          <p className="dateclass">DATE</p>
                          <p className="dateclasstwo">
                            {new Date(
                              deposit.blockTimestamp * 1000
                            ).toLocaleDateString()}
                          </p>
                        </div>
                        <div>
                          <p className="amount">AMOUNT STAKED</p>
                          <p className="digits">
                            {(deposit?.amount / 1e18).toLocaleString()} DOP
                          </p>
                        </div>
                      </div>
                      <p className="cooldown">Cooldown Period</p>

                      <div
                        key={index}
                      // className={
                      //   timersUp[index] ? "secondclassagain" : "secondclass"
                      // }
                      >
                        {timeIsUpState[index] ? (
                          <p className="datatableone secondclassagain" key={index}>
                            <CountdownTimer
                              setTimeIsUp={() => handleTimerComplete(index)}
                              targetEpochTime={deposit?.claimRequestTime}
                            />
                          </p>
                        ) : (
                          <p className="datatableone secondclass" key={index}>
                            <CountdownTimer
                              setTimeIsUp={() => handleTimerComplete(index)}
                              targetEpochTime={deposit?.claimRequestTime}
                            />
                          </p>
                        )}
                      </div>
                      {timeIsUpState[index] && deposit.status ? (
                        <button
                          onClick={() => {
                            setWithdrawAmount(
                              (deposit?.amount / 1e18).toLocaleString()
                            );
                            setSelectedIndex(deposit.index); // Set the selected index
                            handleShow();
                          }}
                          className="thirdclass2 mt-2"
                        >
                          WITHDRAW
                        </button>
                      ) : (
                        <button className="thirdclass mt-2" disabled>
                          {deposit.status ? " WITHDRAW Disable" : "Claimed"}
                        </button>
                      )}
                    </div>
                  ))}
                </div>
                {
                  activeDepositCount > 0 &&  <div className="Paginationlattable">
                  <div className="left-f">{/* <h6>SHOWING 1-10 OF 10</h6> */}</div>
                  <div className="pagination">
                    <Pagination>
                      <Pagination.Prev
                        onClick={() =>
                          setCurrentPage((prev) => Math.max(prev - 1, 1))
                        }
                      />
                      {[
                        ...Array(Math.ceil(activeDepositCount / pageSize)).keys(),
                      ].map((number) => (
                        <Pagination.Item
                          key={number + 1}
                          active={number + 1 === currentPage}
                          onClick={() => setCurrentPage(number + 1)}
                        >
                          {number + 1}
                        </Pagination.Item>
                      ))}
                      <Pagination.Next
                        onClick={() => setCurrentPage((prev) => prev + 1)}
                      />
                    </Pagination>
                  </div>
                </div>
                }
               
              </div>
            </div>
          )
          :
          (
            <div className="stakingstatbox">
              <div className="wrapper">
                <img src="\assets\upload.png" alt="img" className="img-fluid" />
                <p className="whitetext">Your DOP UnStaking stats will appear here </p>
                <button className="waletconnectss" onClick={() => { setShow111(true) }}>Connect Wallet</button>
              </div>
            </div>
          )
        }


      </section>
      <Modal
        className="generic-modal"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Body>
          <div className="headmodal">
            <p className="paragraphmodal">Withdraw Amount</p>
            <h1 className="headingmodal">
              {withdrawAmount ? withdrawAmount : 0} DOP
            </h1>
          </div>
          <div className="divmid">
            {/* <p className="feediv">Transaction Fee</p>
            <p className="numdiv">$8.522 (0.01 ETH)</p> */}
          </div>
          <div className="mainbtns">
            <button className="dullbtn" onClick={handleClose}>
              Cancel
            </button>
            <button
              className="brdrbtn"
              onClick={() => {
                withdraw(selectedIndex); // Call the withdraw function with the selected index
                handleClose();
              }}
            >
              WITHDRAW
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="generic-modal stakingmodals"
        show={show2}
        onHide={handleClose2}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Withdraw</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="happy">
            <img
              src="\assets\happy.png"
              alt="img"
              className="img-fluid formsrgin"
            />
            <h6>{withdrawAmount ? withdrawAmount : 0} DOP</h6>
            <p>Withdraw Amount successfully</p>
            <button className="commonbtn" onClick={handleClose2}>
              OK
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="generic-modal stakingmodals"
        show={show8}
        onHide={handleClose8}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Claim Rewards</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="happy">
            <img
              src="\assets\vector.png"
              alt="img"
              className="img-fluid 
formsrgin"
            />

            <p className="wrong">Oops, something went wrong</p>
            <button className="commonbtn" onClick={handleClose8}>
            OK
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Myunstaking;
