import React, { useEffect, useState } from 'react'
import "./faqs.scss"
import { Accordion } from 'react-bootstrap';
import Navbar from '../landing/header/Navbar';
import Footer from '../landing/footer/Footer';




const Faqs = () => {
    const [activeKey, setActiveKey] = useState('0');
    const [checkaccount, setCheckAccount] = useState(false);
    const [showWallettt, setShowWallettt] = useState(false);
    const [loader, setLoader] = useState(false);

    const handleSelect = (key) => {
        setActiveKey(key);
    };

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Navbar handleShow={handleShow} show={show} setShow={setShow} />
            <section className="faqs-section">
                <img
                    src="\assets\banner-bg.png"
                    alt="img"
                    className="img-fluid banner-bg banner-bg-dark"
                />
                <div className="custom-container">
                    <div className="faqs-content animate__animated animate__fadeInDown animate__fast">
                        <h4>Staking FAQ</h4>
                        <p>To start staking, go to <a href="https://staking.dop.org/" target='_blank'>staking.dop.org</a>.</p>
                    </div>
                </div>
                <div className="innerfaq animate__animated animate__fadeInUp animate__fast">
                    <Accordion activeKey={activeKey} onSelect={handleSelect}>
                        <Accordion.Item eventKey="0" className={activeKey === '0' ? 'active' : ''}>
                            <Accordion.Header>
                                <p>
                                    <span>01.</span> How many DOP tokens are allocated for staking rewards?
                                </p>
                            </Accordion.Header>
                            <Accordion.Body>
                                A total of 210,000,000 DOP tokens are allocated for staking rewards in the first year.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1" className={activeKey === '1' ? 'active' : ''}>
                            <Accordion.Header>
                                <p>
                                    <span>02.</span> Does the staking program affect the total or circulating supply of DOP tokens?
                                </p>
                            </Accordion.Header>
                            <Accordion.Body>
                                No, the staking rewards are sourced from existing token supplies allocated to the Ecosystem and Community (SGF) Fund, Long-Term Treasury Fund (LTF), and Team Fund. This does not affect the total or circulating supply.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2" className={activeKey === '2' ? 'active' : ''}>
                            <Accordion.Header>
                                <p>
                                    <span>03.</span> How do I start staking my DOP tokens?
                                </p>
                            </Accordion.Header>
                            <Accordion.Body>
                                You can start staking your DOP tokens by visiting our staking website at staking.dop.org and following the instructions to lock up your tokens.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3" className={activeKey === '3' ? 'active' : ''}>
                            <Accordion.Header>
                                <p>
                                    <span>04.</span> When do I start earning rewards?
                                </p>
                            </Accordion.Header>
                            <Accordion.Body>
                                You start earning rewards immediately upon staking your DOP tokens.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4" className={activeKey === '4' ? 'active' : ''}>
                            <Accordion.Header>
                                <p>
                                    <span>05.</span> Can I unstake my tokens at any time?
                                </p>
                            </Accordion.Header>
                            <Accordion.Body>
                                Yes, you can unstake your tokens at any time, but there is a 7-day cooldown period before they are released. Rewards stop accruing once you request to unstake.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5" className={activeKey === '5' ? 'active' : ''}>
                            <Accordion.Header>
                                <p>
                                    <span>06.</span> How long is the lockup period for staking rewards?
                                </p>
                            </Accordion.Header>
                            <Accordion.Body>
                                Staking rewards can be claimed after a 90-day lockup period from the initial staking date.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6" className={activeKey === '6' ? 'active' : ''}>
                            <Accordion.Header>
                                <p>
                                    <span>07.</span> Can I restake my rewards?
                                </p>
                            </Accordion.Header>
                            <Accordion.Body>
                                Yes, you can restake your rewards to compound your earnings. However, restaked rewards will be subject to a new 90-day lockup period.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7" className={activeKey === '7' ? 'active' : ''}>
                            <Accordion.Header>
                                <p>
                                    <span>08.</span> How is the APY calculated?
                                </p>
                            </Accordion.Header>
                            <Accordion.Body>
                                The APY is dynamic and depends on the total amount of tokens staked in the system. It fluctuates constantly and is adjusted every second based on demand. Early stakers may enjoy higher APY rates initially because the APY decreases as more tokens are staked over time.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="8" className={activeKey === '8' ? 'active' : ''}>
                            <Accordion.Header>
                                <p>
                                    <span>09.</span> What are the additional incentives for staking?
                                </p>
                            </Accordion.Header>
                            <Accordion.Body>
                                During the Mainnet Airdrop Campaign, stakers earn 0.05 points per 1 DOP staked per 24 hours, plus a 10% bonus for every 30 days of continuous staking.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="9" className={activeKey === '9' ? 'active' : ''}>
                            <Accordion.Header>
                                <p>
                                    <span>10.</span> What happens when I request to unstake my tokens?
                                </p>
                            </Accordion.Header>
                            <Accordion.Body>
                                When you request to unstake your tokens, there is a 7-day cooldown period before they are released, and rewards stop accruing immediately.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="10" className={activeKey === '10' ? 'active' : ''}>
                            <Accordion.Header>
                                <p>
                                    <span>11.</span> Can I claim my rewards if I unstake my tokens?
                                </p>
                            </Accordion.Header>
                            <Accordion.Body>
                                Yes, but you must wait 90 days from the initial staking date to claim them.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="11" className={activeKey === '11' ? 'active' : ''}>
                            <Accordion.Header>
                                <p>
                                    <span>12.</span> How can I stake DOP tokens if they are currently non-transferable until listed on an exchange?
                                </p>
                            </Accordion.Header>
                            <Accordion.Body>
                                We have made an exception in the smart contract to allow DOP tokens to be moved only to the staking pool and back to the wallet that made the staking. This ensures that tokens can be staked while still preventing them from being transferred to any other destination.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
                <div className="custom-container">
                    <div className="bottom-resource">
                        <p>Relevant resources:</p>
                        <a href="https://dop.org/blog/staking" target='_blank'>Blog post: Everything you need to know about DOP’s staking program</a>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Faqs