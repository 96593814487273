import React, { useState, useEffect } from "react";
import "./datatable.scss";
import { Link } from "react-router-dom";
import { Accordion, Pagination } from "react-bootstrap"
import { parseInt } from "lodash";

const StakingTable = ({ setCurrentPage, currentPage, pageSize, indexwaits, activeDepositCount, activeDeposit, account, getDepositData }) => {

  // console.log("data here", activeDepositCount)
  // Function to convert timestamp to date and time
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    return date?.toLocaleString('en-GB', options).split(', ');
  };
  useEffect(() => {
    if (account && currentPage === 1 || indexwaits === 'MYSTAKING') {
      getDepositData(currentPage, pageSize)
    } else if (account || indexwaits === 'MYSTAKING') {
      getDepositData(currentPage, pageSize)
    }
    // getDepositData(currentPage, pageSize);
  }, [account, currentPage, indexwaits]);


  const totalPages = Math.ceil(activeDepositCount / pageSize);

  return (
    <>
      <div className="mytable">
        <section className="datatable">
          <div className="bgtable">
            <div className="table-responsive" style={{ position: "relative" }}>
              <table class="table">
                <thead>
                  <tr>
                    <th>
                      <div
                        className="parent-tag"
                      >
                        Date
                        <div
                          className="filter-btn"
                          style={{ cursor: "pointer" }}
                        >
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.90908 5.14258H4.09498H0.660442C0.072714 5.14258 -0.22115 4.36198 0.195157 3.90439L3.36644 0.418619C3.87458 -0.139912 4.70107 -0.139912 5.20921 0.418619L6.41528 1.74429L8.38049 3.90439C8.79068 4.36198 8.49681 5.14258 7.90908 5.14258Z"
                              fill="#242627"
                            />
                          </svg>
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.90908 0.857422H4.09498H0.660442C0.072714 0.857422 -0.22115 1.63802 0.195157 2.09561L3.36644 5.58138C3.87458 6.13991 4.70107 6.13991 5.20921 5.58138L6.41528 4.25571L8.38049 2.09561C8.79068 1.63802 8.49681 0.857422 7.90908 0.857422Z"
                              fill="#242627"
                            />
                          </svg>
                        </div>
                      </div>
                    </th>
                    <th>
                      <div
                        className="parent-tag"
                      >
                        Activity
                      </div>
                    </th>
                    <th>
                      <div
                        className="parent-tag"
                      >
                        AMOUNT STAKED

                      </div>
                    </th>

                    <th>
                      <div className="parent-tag">
                        TX Hash
                        <div
                          className="filter-btn"
                          style={{ cursor: "pointer" }}
                        >
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.90908 5.14258H4.09498H0.660442C0.072714 5.14258 -0.22115 4.36198 0.195157 3.90439L3.36644 0.418619C3.87458 -0.139912 4.70107 -0.139912 5.20921 0.418619L6.41528 1.74429L8.38049 3.90439C8.79068 4.36198 8.49681 5.14258 7.90908 5.14258Z"
                              fill="#242627"
                            />
                          </svg>
                          <svg
                            width="9"
                            height="6"
                            viewBox="0 0 9 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.90908 0.857422H4.09498H0.660442C0.072714 0.857422 -0.22115 1.63802 0.195157 2.09561L3.36644 5.58138C3.87458 6.13991 4.70107 6.13991 5.20921 5.58138L6.41528 4.25571L8.38049 2.09561C8.79068 1.63802 8.49681 0.857422 7.90908 0.857422Z"
                              fill="#242627"
                            />
                          </svg>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {activeDeposit?.map((deposit, index) => {
                    // console.log("time", deposit)
                    const [date, time] = formatTimestamp(deposit?.blockTimestamp);
                    return (
                      <tr key={index}>
                        <td>
                          <div className="stakingdatesmain">
                            <h6 className="datepara">    {new Date(
                                    deposit?.blockTimestamp * 1000
                                  ).toLocaleDateString()}</h6>
                            <p className="timepara">   {new Date(
                              deposit?.blockTimestamp * 1000
                            ).toLocaleTimeString()}</p>
                          </div>
                        </td>
                        <td>Staked</td>
                        <td>{(deposit?.amount / 1e18).toLocaleString()} DOP</td>
                        <td>
                          <a href={`https://etherscan.io/tx/${deposit?.transactionHash}`} target="_blank" className="hashpara">
                            {deposit?.transactionHash?.slice(0, 6)}...{deposit?.transactionHash?.slice(-4)}
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                              <path d="M7.5835 6.41663L12.3668 1.6333" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M12.8332 3.96675V1.16675H10.0332" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M6.4165 1.16675H5.24984C2.33317 1.16675 1.1665 2.33341 1.1665 5.25008V8.75008C1.1665 11.6667 2.33317 12.8334 5.24984 12.8334H8.74984C11.6665 12.8334 12.8332 11.6667 12.8332 8.75008V7.58342" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {
              activeDepositCount > 0 &&
              (
                <div className="footer-content">

                  <div className="left-f">
                    <h6>
                      SHOWING {currentPage * pageSize - pageSize + 1}-
                      {Math.min(currentPage * pageSize, activeDepositCount)} OF {activeDepositCount}
                    </h6>
                  </div>
                  <div className="right-f">
                    <Pagination>
                      <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} />
                      {[...Array(Math.ceil(activeDepositCount / pageSize)).keys()].map(number => (
                        <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => setCurrentPage(number + 1)}>
                          {number + 1}
                        </Pagination.Item>
                      ))}
                      <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} />
                    </Pagination>
                  </div>
                </div>
              )
            }

          </div>
        </section>
      </div>

      <div className="mytablemobile d-none">
        <div className="mainupperr">
          {activeDeposit?.map((deposit, index) => {
            const [date] = formatTimestamp(deposit?.claimTime);
            return (
              <div className="mbltable">
                <div className="mbltableinner">
                  <div className="mbltabledetail">
                    <p className="mblpara">DATE</p>
                    <h6 className="mblhead"> {new Date(
                                    deposit?.blockTimestamp * 1000
                                  ).toLocaleDateString()}</h6>
                  </div>
                  <div className="mbltabledetail">
                    <p className="mblpara">TX HASH</p>
                    <a href={`https://etherscan.io/tx/${deposit?.transactionHash}`} className="mblheadlink">
                      {deposit.transactionHash.slice(0, 6)}...{deposit.transactionHash.slice(-4)}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="13"
                        viewBox="0 0 12 13"
                        fill="none"
                      >
                        <path
                          d="M6.5 6.00002L10.6 1.90002"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11.0001 3.9V1.5H8.6001"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M5.5 1.5H4.5C2 1.5 1 2.5 1 5V8C1 10.5 2 11.5 4.5 11.5H7.5C10 11.5 11 10.5 11 8V7"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
                <div className="mbltableinner">
                  <div className="mbltabledetail">
                    <p className="mblpara">ACTIVITY</p>
                    <h6 className="mblhead">Staked</h6>
                  </div>
                  <div className="mbltabledetail">
                    <p className="mblpara">AMOUNT STAKED</p>
                    <p className="mblhead">{(deposit?.amount / 1e18).toLocaleString()} DOP</p>
                  </div>
                </div>
              </div>
            )
          })}

          {
            activeDepositCount > 0 &&
            (
              <div className="footer-content">
                <div className="left-f">
                  <h6>
                    SHOWING {currentPage * pageSize - pageSize + 1}-
                    {Math.min(currentPage * pageSize, activeDepositCount)} OF {activeDepositCount}
                  </h6>
                </div>
                <div className="right-f">
                  <Pagination>
                    <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} />
                    {[...Array(Math.ceil(activeDepositCount / pageSize)).keys()].map(number => (
                      <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => setCurrentPage(number + 1)}>
                        {number + 1}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} />
                  </Pagination>
                </div>
                {/* <div className="right-f">
                <Pagination>
                    <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} />
                    {[...Array(15).keys()].map(number => (
                        <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => setCurrentPage(number + 1)}>
                            {number + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next onClick={() => setCurrentPage(prev => prev + 1)} />
                </Pagination>
            </div> */}
              </div>
            )
          }

        </div>

      </div>
    </>
  );
};

export default StakingTable;
