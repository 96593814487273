import React from 'react';
import './footer.scss';

const Footer = () => {

    return (
        <div className="wrapper-footer">
            <section className="main-footer">
                <div className="nav-container">
                    <div className="parent">
                        <img src="\logo.svg" alt="img" className='img-fluid' />
                        <p className='right-reserved-text'>© 2024 DOP, All Rights Reserved</p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Footer;
