import { Link } from "react-router-dom";
import "./navbar.scss";
import { useState, useEffect } from "react";
import useAuth from '../../../hooks/useAuth';
// import Environment from '@/utils/Enviroment';
import axios from 'axios';
import { toast } from "react-toastify";
import { setupNetwork } from "../../../utils/wallet";

import Modal from 'react-bootstrap/Modal';
import { useWeb3React } from '@web3-react/core';
import useWeb3 from '../../../hooks/useWeb3';
import { Offcanvas } from "react-bootstrap";
const Navbar = ({ handleShow, show, setShow }) => {
  let { account, chainId } = useWeb3React();
  const web3 = useWeb3();
  // console.log("account here is", account, chainId)

  const { login, logout } = useAuth();
  const handleClose = () => setShow(false);

  const connectWallet = async (e) => {
    if (account) {
      const connectorId = window.localStorage.getItem("connectorId")
      await logout(connectorId);
      localStorage.removeItem("connectorId");
      localStorage.removeItem("flag");
    } else {
      await login("injected", e);
      localStorage.setItem("connectorId", "injected");
      localStorage.setItem("flag", "true");
      localStorage.setItem("chain", e);
      handleClose();
      // setclickedbtn(false)
      // setLoader(false);
    }
    // setLoader(false);
  };

  const trustWallet = async (e) => {
    handleClose();
    try {
      if (account) {
        await logout("walletconnect");
      } else {
        login("walletconnect", e);
        localStorage.setItem('connectorId', 'walletconnect');
        localStorage.setItem("flag", "true");
      }
    } catch (error) {
      console.error('Error during WalletConnect operation:', error);
      toast.error('An error occurred during WalletConnect operation');
    }
  };

  const disconnectWallet = async () => {
    const connectorId = window.localStorage.getItem("connectorId")
    logout(connectorId)
    localStorage.removeItem('connectorId')
    localStorage.removeItem('flag')
    localStorage.removeItem('chain')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('wallet')
  };


  useEffect(() => {
    // Update the timer every second
    if (chainId != 1) {
      setupNetwork(1)
    }
  }, [chainId]);


  const [showoff, setShowoff] = useState(false);

  const handleCloseoff = () => setShowoff(false);
  const handleShowoff = () => setShowoff(true);


  const [show111, setShow111] = useState(false);
  const handleClose111 = () => setShow111(false);
  const handleShow111 = () => setShow111(true);



  return (
    <>
      <section className="main-navbar">
        <div className="nav-container">
          <nav class="navbar navbar-expand-xl">
            <Link class="navbar-brand" to="/"><img src="\logo.svg" alt="img" className="img-fluid" /></Link>
            <div className="twice-btns">
            <a onClick={handleShow111} className="howitworks-btn">how it works?</a>
              <Link to="/faqs" className="faqs-btn">FAQs</Link>
              {account ?
                (
                  <button onClick={disconnectWallet} className="btn-disconnect">
                    <h6>{account?.slice(0, 8)}...
                      {account?.slice(
                        account?.length - 3,
                        account?.length
                      )}</h6>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                      <path d="M10.795 16.2746H10.7029C7.55795 16.2746 6.04211 15.035 5.78003 12.2583C5.7517 11.9679 5.9642 11.7058 6.2617 11.6775C6.54503 11.6492 6.8142 11.8687 6.84253 12.1592C7.04795 14.3833 8.09628 15.2121 10.71 15.2121H10.8021C13.685 15.2121 14.705 14.1921 14.705 11.3092V6.69082C14.705 3.8079 13.685 2.7879 10.8021 2.7879H10.71C8.08211 2.7879 7.03378 3.63082 6.84253 5.89749C6.80711 6.1879 6.5592 6.40749 6.2617 6.37915C5.9642 6.3579 5.7517 6.09582 5.77295 5.8054C6.01378 2.98624 7.5367 1.7254 10.7029 1.7254H10.795C14.2729 1.7254 15.7604 3.2129 15.7604 6.69082V11.3092C15.7604 14.7871 14.2729 16.2746 10.795 16.2746Z" fill="#FE1515" />
                      <path d="M10.625 9.53125H2.56421C2.27379 9.53125 2.03296 9.29042 2.03296 9C2.03296 8.70958 2.27379 8.46875 2.56421 8.46875H10.625C10.9155 8.46875 11.1563 8.70958 11.1563 9C11.1563 9.29042 10.9155 9.53125 10.625 9.53125Z" fill="#FE1515" />
                      <path d="M4.14385 11.9042C4.00927 11.9042 3.87468 11.8546 3.76843 11.7483L1.39552 9.37541C1.1901 9.16999 1.1901 8.82999 1.39552 8.62457L3.76843 6.25166C3.97385 6.04624 4.31385 6.04624 4.51927 6.25166C4.72468 6.45707 4.72468 6.79707 4.51927 7.00249L2.52177 8.99999L4.51927 10.9975C4.72468 11.2029 4.72468 11.5429 4.51927 11.7483C4.4201 11.8546 4.27843 11.9042 4.14385 11.9042Z" fill="#FE1515" />
                    </svg>
                  </button>
                )
                :
                (
                  <button onClick={handleShow} className="btn-launch">
                    <h6>Connect Wallet</h6>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                      <path d="M9.20801 6.875H4.95801" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M15.5833 8.27032V9.72953C15.5833 10.1191 15.2717 10.4378 14.875 10.452H13.4866C12.7216 10.452 12.0204 9.89242 11.9567 9.12742C11.9142 8.68117 12.0841 8.26325 12.3816 7.97283C12.6437 7.70367 13.005 7.54785 13.4016 7.54785H14.875C15.2717 7.56202 15.5833 7.88074 15.5833 8.27032Z" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M12.382 7.97299C12.0845 8.26341 11.9145 8.68133 11.957 9.12758C12.0207 9.89258 12.722 10.4522 13.487 10.4522H14.8753V11.4792C14.8753 13.6042 13.4587 15.0209 11.3337 15.0209H4.95866C2.83366 15.0209 1.41699 13.6042 1.41699 11.4792V6.52091C1.41699 4.59425 2.57866 3.24841 4.38491 3.02175C4.56908 2.99341 4.76033 2.97925 4.95866 2.97925H11.3337C11.5178 2.97925 11.6949 2.98632 11.8649 3.01466C13.6924 3.22716 14.8753 4.58008 14.8753 6.52091V7.54801H13.402C13.0053 7.54801 12.6441 7.70382 12.382 7.97299Z" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </button>
                )
              }
            </div>
            <a onClick={handleShowoff} className="bar-btn d-none"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M3 7H21" stroke="white" stroke-width="1.5" stroke-linecap="round" />
              <path d="M3 12H21" stroke="white" stroke-width="1.5" stroke-linecap="round" />
              <path d="M3 17H21" stroke="white" stroke-width="1.5" stroke-linecap="round" />
            </svg></a>


          </nav>
        </div>
      </section>
      <Modal className="generic-modal" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Connect Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="walletmains">
            <div className="walletinner sddsdsdsdsdsds" onClick={connectWallet} >
              <img src="\assets\metamaskimg.svg" alt="walletimg" className="walletimg" />
              <p className="walletpara">Metamask</p>
            </div>
            <div className="walletinner" onClick={() => {
              trustWallet();
              setShowoff(false);
            }}>
              <img src="\assets\walletconnectimg.svg" alt="walletimg" className="walletimg" />
              <p className="walletpara">WalletConnect</p>
            </div>
          </div>
          {/* <button className="commonbtn">OKAY</button>
          <div className="mainbtns">
          <button className="dullbtn">Cancel</button>
          <button className="brdrbtn">Confirm</button>
          </div> */}
        </Modal.Body>
      </Modal>


      <Offcanvas className="offcanvas-bar" show={showoff} onHide={handleCloseoff}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title> <a href="/"><img src="\logo.svg" alt="img" className="img-fluid" /></a> </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="multi-btns">
            <a onClick={handleShow111} className="faqs-btn">how it works?</a>
            <Link to="/faqs" className="faqs-btn">FAQs</Link>
            {account ?
              (
                <button onClick={disconnectWallet} className="btn-disconnect">
                  <h6>{account?.slice(0, 8)}...
                    {account?.slice(
                      account?.length - 3,
                      account?.length
                    )}</h6>
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                    <path d="M10.795 16.2746H10.7029C7.55795 16.2746 6.04211 15.035 5.78003 12.2583C5.7517 11.9679 5.9642 11.7058 6.2617 11.6775C6.54503 11.6492 6.8142 11.8687 6.84253 12.1592C7.04795 14.3833 8.09628 15.2121 10.71 15.2121H10.8021C13.685 15.2121 14.705 14.1921 14.705 11.3092V6.69082C14.705 3.8079 13.685 2.7879 10.8021 2.7879H10.71C8.08211 2.7879 7.03378 3.63082 6.84253 5.89749C6.80711 6.1879 6.5592 6.40749 6.2617 6.37915C5.9642 6.3579 5.7517 6.09582 5.77295 5.8054C6.01378 2.98624 7.5367 1.7254 10.7029 1.7254H10.795C14.2729 1.7254 15.7604 3.2129 15.7604 6.69082V11.3092C15.7604 14.7871 14.2729 16.2746 10.795 16.2746Z" fill="#FE1515" />
                    <path d="M10.625 9.53125H2.56421C2.27379 9.53125 2.03296 9.29042 2.03296 9C2.03296 8.70958 2.27379 8.46875 2.56421 8.46875H10.625C10.9155 8.46875 11.1563 8.70958 11.1563 9C11.1563 9.29042 10.9155 9.53125 10.625 9.53125Z" fill="#FE1515" />
                    <path d="M4.14385 11.9042C4.00927 11.9042 3.87468 11.8546 3.76843 11.7483L1.39552 9.37541C1.1901 9.16999 1.1901 8.82999 1.39552 8.62457L3.76843 6.25166C3.97385 6.04624 4.31385 6.04624 4.51927 6.25166C4.72468 6.45707 4.72468 6.79707 4.51927 7.00249L2.52177 8.99999L4.51927 10.9975C4.72468 11.2029 4.72468 11.5429 4.51927 11.7483C4.4201 11.8546 4.27843 11.9042 4.14385 11.9042Z" fill="#FE1515" />
                  </svg>
                </button>
              )
              :
              (
                <button onClick={handleShow} className="btn-launch">
                  <h6>Connect Wallet</h6>
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                    <path d="M9.20801 6.875H4.95801" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M15.5833 8.27032V9.72953C15.5833 10.1191 15.2717 10.4378 14.875 10.452H13.4866C12.7216 10.452 12.0204 9.89242 11.9567 9.12742C11.9142 8.68117 12.0841 8.26325 12.3816 7.97283C12.6437 7.70367 13.005 7.54785 13.4016 7.54785H14.875C15.2717 7.56202 15.5833 7.88074 15.5833 8.27032Z" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M12.382 7.97299C12.0845 8.26341 11.9145 8.68133 11.957 9.12758C12.0207 9.89258 12.722 10.4522 13.487 10.4522H14.8753V11.4792C14.8753 13.6042 13.4587 15.0209 11.3337 15.0209H4.95866C2.83366 15.0209 1.41699 13.6042 1.41699 11.4792V6.52091C1.41699 4.59425 2.57866 3.24841 4.38491 3.02175C4.56908 2.99341 4.76033 2.97925 4.95866 2.97925H11.3337C11.5178 2.97925 11.6949 2.98632 11.8649 3.01466C13.6924 3.22716 14.8753 4.58008 14.8753 6.52091V7.54801H13.402C13.0053 7.54801 12.6441 7.70382 12.382 7.97299Z" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </button>
              )
            }
          </div>
        </Offcanvas.Body>
      </Offcanvas>


      <Modal
        className="generic-modal stakingmodals howitworksmodal"
        show={show111}
        onHide={handleClose111}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Welcome to the DOP Staking Program</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="howitworks-content">
            <h5>Here's how it works:</h5>
            <ul>
              <li>Start earning rewards as soon as you stake your tokens.</li>
              <li>You can unstake your tokens whenever you want, but they will only be released after a 7-day waiting period. Please note that rewards will stop accumulating once you request to unstake.</li>
              <li>Rewards can be claimed after a 90-day lockup period, which starts from the initial staking date.</li>
              <li>To compound your earnings, you can restake your rewards. However, this will initiate a new 90-day lockup period for the restaked rewards.</li>
            </ul>
            <h6>Important information about the Annual Percentage Yield (APY):</h6>
            <p className="para">The APY is dependent on the total number of tokens staked in the system and is subject to constant fluctuations. For instance, if the initially offered APY is 200%, it may decrease to 100% if the total amount of staked tokens doubles.</p>
            <p className="para"> If you have any further questions, please don't hesitate to reach out to our support team. Happy staking!</p>
          
          
          
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Navbar;
